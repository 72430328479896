import { mapGetters } from 'vuex'
import portfolio from '../../../constant/portfolio'
export default {
  name: 'g2lab',
  data: () => ({
    solutionLists: ['Innovate with the industry’s best global talents', 'Upskill your organization with corporate training', 'Corporate projects that drives digital transformation'],
    projectLists: [
      {
        projectName: 'Astrapay',
        companyName: 'PT. Astra Digital Arta',
        description:
          'As an electronic money service developed by PT. Astra Digital Arta (trademarked as AstraPay) and a part of the Astra Group, this app functions as a mobile-based payment platform where users can easily transfer funds for bill payments, top-up mobile credit, making installments, and many more.',
        image: require('@/assets/images/project/astrapay.webp'),
        category: 'E-wallet App'
      },
      {
        projectName: 'GoToko App',
        companyName: 'GoToko',
        description: 'GoToko is an app shop-owners and small retailers used to purchase items such as food, groceries, drinks, household supplies, etc.',
        image: require('@/assets/images/project/gotoko.webp'),
        category: 'E-commerce App'
      },
      {
        projectName: 'HRIS - Integral App',
        companyName: 'BTPNS',
        description:
          'BTPNS entrusted G2Academy  in implementing the Sharia Intensive Development Program (ShiftED) to train aspiring developers. Moreover, the team was also involved in a joint development to create the Integral BTPNS System.',
        image: require('@/assets/images/project/btpns.webp'),
        category: 'Integral App'
      },
      {
        projectName: 'New Delivery System',
        companyName: 'BRI',
        description: 'NDS (New Delivery System) includes features that supports ‘product delivery’ and enables customers to anticipate competition in the retail banking industry.',
        image: require('@/assets/images/project/bri.webp'),
        category: 'Web & Mobile App'
      },
      {
        projectName: 'Data Warehouse & CRM System',
        companyName: 'Telkomsat',
        description: 'Training, planning, building, and implementation an Enterprise Data Warehouse, and CRM System (Join Development.',
        image: require('@/assets/images/project/telkomsat.webp'),
        category: 'Data Warehouse'
      },
      {
        projectName: 'Deep Learning Based Oil Palm Tree Detection',
        companyName: 'Bestagro',
        description: 'As one of the largest palm oil plantation in Indonesia, Bestagro teamed up with G2Academy to build a Smart Detection and Object Counting System using Deep Learning.',
        image: require('@/assets/images/project/bestagro.webp'),
        category: 'AI & Machine Learning'
      }
    ],
    corporateTraining: [
      {
        title: 'Decades of Industry Experienced Talent',
        description: 'Our team of experts with decades of industry expertise, the company can help clients achieve their software development goals and stay ahead of the competition.'
      },
      {
        title: 'Cost-Effective Solutions',
        description: 'We commit to deliver innovative software solutions that drive business success to help business of all sizes.'
      },
      {
        title: 'Scalability & Faster Time to Market',
        description:
          'We will deliver software solutions that grow with your businesses and get to market faster, and committed to help you achieve their software development goals quickly and efficiently.'
      }
    ],
    projectListsOptions: {
      type: 'slide',
      autoplay: true,
      pagination: true,
      arrows: false,
      keyboard: false,
      perPage: 4,
      drug: true,
      speed: 1000,
      interval: 3000,
      gap: '1rem',
      lazyLoad: 'nearby',
      padding: { left: 2, right: 2 },
      breakpoints: {
        767: {
          perPage: 1,
          type: 'loop'
        }
      }
    },
    partners: ['astrapay', 'gotoko', 'btpns', 'bri', 'telkomsat', 'best', 'jet-brains', 'smartfren'],
    testimonials: [
      {
        image: require('@/assets/images/people/teguh.jpg'),
        name: 'Teguh winarto',
        position: 'Group Head Outlet Delivery Platform Development at PT BRI Persereoan (TBK)',
        message:
          'So far, BRI’s collaboration with G2Academy is going smoothly. This initiative and project we had to transform all of BRI branches across Indonesia was very well executed by the G2Academy team. We are grateful to talented individuals that have supported BRI throughout this entire process.'
      },
      {
        image: require('@/assets/images/people/yoga.jpeg'),
        name: 'Yoga Wigardo',
        position: 'Lead Product at GoToko',
        message:
          'G2Academy played a significant role in scaling up GoToko into a reliable product within a short amount of time. GoToko partnered up with G2Academy because we needed their help in achieving certain objectives. We love how passionate they are. Their high quality of work provided the boost needed to speed up development. Looking forward to many future collaborations with G2Academy. Thank you!'
      },
      {
        image: require('@/assets/images/people/nugroho.jpg'),
        name: 'Nugroho Wibisono',
        position: 'GM IT & Digital at PT. TELKOM SATELIT INDONESIA',
        message:
          'G2Academy not only understood our customers’ needs, but they also customized the strategy accordingly. They provided impactful solutions, and their team was very ‘up to date’ in terms of technology, sharing session methods, as well as insights on customer needs. G2Academy is top notch, highly recommended!'
      }
    ],
    carouselTestimonyOptions: {
      type: 'loop',
      autoplay: true,
      arrows: false,
      perPage: 3,
      speed: 1000,
      pagination: false,
      interval: 5000,
      gap: '1rem',
      lazyLoad: 'nearby',
      breakpoints: {
        767: {
          perPage: 1,
          pagination: true
        }
      }
    },

    currentPortofolioPage: 1,
    pageSize: 6,
    isVisibleDetail: false,
    selectedPortfolio: 0
  }),
  components: {
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    SoftSkill2: () => import(/* webpackChunkName: "softskill2" */ '@/components/icons/SoftSkill2'),
    Student: () => import(/* webpackChunkName: "student" */ '@/components/icons/Student'),
    School: () => import(/* webpackChunkName: "school" */ '@/components/icons/School'),
    GlobeOutline: () => import(/* webpackChunkName: "GlobeOutline" */ '@/components/icons/GlobeOutline'),
    StarChart: () => import(/* webpackChunkName: "StarChart" */ '@/components/icons/StarChart'),
    CodeSlash: () => import(/* webpackChunkName: "code-slash" */ '@/components/icons/CodeSlash'),
    People: () => import(/* webpackChunkName: "people" */ '@/components/icons/People'),
    Rocket: () => import(/* webpackChunkName: "rocket" */ '@/components/icons/Rocket'),
    PersonalPortfolio: () => import(/* webpackChunkName: "personalportofolio" */ '@/components/icons/PersonalPortfolio'),
    ProductCard: () => import(/* webpackChunkName: "image" */ '@/components/card/ProductCard'),
    Carousel: () => import(/* webpackChunkName: "carousel" */ '@/components/carousel/Carousel'),
    TestimonyCard: () => import(/* webpackChunkName: "image" */ '@/components/card/TestimonyCard'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal')
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile']),
    videoLink() {
      return 'https://www.youtube.com/embed/UV3PJ63bGVE'
    },
    partnersCol1() {
      return this.isMobile ? this.partners.slice(0, 3) : this.partners
    },
    partnersCol2() {
      return this.partners.slice(3, 5)
    },
    partnersCol3() {
      return this.partners.slice(5, 7)
    },
    projectPortfolio() {
      return portfolio.filter((item) => item.type === 'project')
    },
    portfolioList() {
      return this.projectPortfolio.slice(0, this.currentPortofolioPage * this.pageSize)
    },
    totalPortfolio() {
      return this.projectPortfolio.length
    }
  },
  methods: {
    toContactUs() {
      this.$router.push({ name: 'Contact Us' })
    },
    toPartner() {
      this.$router.push('/register-alliance')
    },
    toggleVisibleDetail(value) {
      this.selectedPortfolio = value || {}
      this.isVisibleDetail = !this.isVisibleDetail
    },
    loadMore() {
      if (this.portfolioList.length !== this.totalPortfolio) {
        this.currentPortofolioPage++
      }
    }
  }
}
